var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "extra-component-google-map-demo" } },
    [
      _vm._m(0),
      _c("google-map-basic"),
      _c("google-map-info-window"),
      _c("google-map-street-view"),
      _c("div", { staticClass: "content-area__content" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-4" }, [
      _c(
        "a",
        {
          attrs: {
            href: "https://xkjyeah.github.io/vue-google-maps/",
            target: "_blank",
            rel: "nofollow",
          },
        },
        [_vm._v("Google maps")]
      ),
      _vm._v(" component for vue with 2-way data binding"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }