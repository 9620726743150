var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Street View", "code-toggler": "" } },
    [
      _c("p", [_vm._v("Example of creating google map - street view")]),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c("h6", [
            _vm._v(
              "Panorama at Pembroke College, Cambridge, facing North, looking slightly upwards (10 degrees)"
            ),
          ]),
          _c("p", { staticClass: "mt-3 mb-5" }, [
            _vm._v("Point-of-view updates when you pan around"),
          ]),
          _c("gmap-street-view-panorama", {
            staticClass: "pano",
            staticStyle: { height: "400px", width: "100%" },
            attrs: {
              position: { lat: 52.201272, lng: 0.11872 },
              pov: _vm.pov,
              zoom: 1,
            },
            on: { pano_changed: _vm.updatePano, pov_changed: _vm.updatePov },
          }),
          _c(
            "vs-list",
            { staticClass: "mt-5" },
            [
              _c("vs-list-item", {
                attrs: { title: ` Heading: ${_vm.pov && _vm.pov.heading}` },
              }),
              _c("vs-list-item", {
                attrs: { title: ` Pitch: ${_vm.pov && _vm.pov.pitch}` },
              }),
              _c("vs-list-item", { attrs: { title: ` Pano ID: ${_vm.pano}` } }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "demo-alignment" },
            [
              _c(
                "vs-button",
                {
                  attrs: { type: "filled" },
                  on: {
                    click: function ($event) {
                      _vm.pov.heading = Math.random() * 360
                    },
                  },
                },
                [_vm._v("Change Heading")]
              ),
              _c(
                "vs-button",
                {
                  attrs: { type: "filled" },
                  on: {
                    click: function ($event) {
                      _vm.pov.pitch = Math.random() * 180 - 90
                    },
                  },
                },
                [_vm._v("Change Pitch")]
              ),
              _c(
                "vs-button",
                {
                  attrs: { type: "filled" },
                  on: {
                    click: function ($event) {
                      ;(_vm.pov.pitch = Math.random() * 180 - 90),
                        (_vm.pov.heading = Math.random() * 360)
                    },
                  },
                },
                [_vm._v("Change Both")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <h6>Panorama at Pembroke College, Cambridge, facing North, looking slightly upwards (10 degrees)</h6>\n  <p class="mt-3 mb-5">Point-of-view updates when you pan around</p>\n\n  <gmap-street-view-panorama class="pano" :position="{lat: 52.201272, lng: 0.118720}"\n    :pov="pov" :zoom="1" @pano_changed="updatePano" @pov_changed="updatePov" style="height: 400px; width: 100%">\n  </gmap-street-view-panorama>\n\n  <vs-list class="mt-5">\n    <vs-list-item :title="` Heading: ${ pov && pov.heading }`"></vs-list-item>\n    <vs-list-item :title="` Pitch: ${ pov && pov.pitch }`"></vs-list-item>\n    <vs-list-item :title="` Pano ID: ${ pano }`"></vs-list-item>\n  </vs-list>\n\n  <div class="demo-alignment">\n    <vs-button type="filled" @click="pov.heading = Math.random() * 360">Change Heading</vs-button>\n    <vs-button type="filled" @click="pov.pitch = Math.random() * 180 - 90">Change Pitch</vs-button>\n    <vs-button type="filled" @click="pov.pitch = Math.random() * 180 - 90, pov.heading = Math.random() * 360">Change Both</vs-button>\n  </div>\n</template>\n\n<script>\nexport default {\n  data() {\n    return {\n      pov: null,\n      pano: null,\n    }\n  },\n  methods: {\n    updatePov(pov) {\n      this.pov = pov;\n    },\n    updatePano(pano) {\n      this.pano = pano;\n    }\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }