var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Info Window", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("A basic example of using a single infowindow for 3 markers"),
      ]),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c(
            "gmap-map",
            {
              staticStyle: { width: "100%", height: "500px" },
              attrs: { center: _vm.center, zoom: 15 },
            },
            [
              _c(
                "gmap-info-window",
                {
                  attrs: {
                    options: _vm.infoOptions,
                    position: _vm.infoWindowPos,
                    opened: _vm.infoWinOpen,
                  },
                  on: {
                    closeclick: function ($event) {
                      _vm.infoWinOpen = false
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.infoContent) +
                      "\n                "
                  ),
                ]
              ),
              _vm._l(_vm.markers, function (m, i) {
                return _c("gmap-marker", {
                  key: i,
                  attrs: { position: m.position, clickable: true },
                  on: {
                    click: function ($event) {
                      return _vm.toggleInfoWindow(m, i)
                    },
                  },
                })
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <gmap-map :center="center" :zoom="15" style="width: 100%; height: 500px">\n    <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">\n      ' +
            _vm._s(_vm.infoContent) +
            '\n    </gmap-info-window>\n    <gmap-marker :key="i" v-for="(m,i) in markers" :position="m.position" :clickable="true" @click="toggleInfoWindow(m,i)"></gmap-marker>\n  </gmap-map>\n</template>\n\n<script>\nexport default {\n  data() {\n    return {\n      center: { lat: 47.376332, lng: 8.547511 },\n      infoContent: \'\',\n      infoWindowPos: null,\n      infoWinOpen: false,\n      currentMidx: null,\n      //optional: offset infowindow so it visually sits nicely on top of our marker\n      infoOptions: {\n      pixelOffset: { width: 0, height: -35 }\n      },\n      markers: [\n        { position: { lat: 47.376332, lng: 8.547511 }, infoText: \'Marker 1\' },\n        { position: { lat: 47.374592, lng: 8.548867 }, infoText: \'Marker 2\' },\n        { position: { lat: 47.379592, lng: 8.549867 }, infoText: \'Marker 3\' }\n      ]\n    }\n  },\n  methods: {\n    toggleInfoWindow: function(marker, idx) {\n      this.infoWindowPos = marker.position;\n      this.infoContent = marker.infoText;\n      //check if its the same marker that was selected if yes toggle\n      if (this.currentMidx == idx) {\n        this.infoWinOpen = !this.infoWinOpen;\n      }\n      //if different marker set infowindow to open and reset current marker index\n      else {\n        this.infoWinOpen = true;\n        this.currentMidx = idx;\n      }\n    }\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }