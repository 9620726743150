var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Basic", "code-toggler": "" } },
    [
      _c("p", [_vm._v("Creating basic google map")]),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c(
            "GmapMap",
            {
              staticStyle: { width: "100%", height: "400px" },
              attrs: { center: _vm.center, zoom: 7, "map-type-id": "terrain" },
            },
            _vm._l(_vm.markers, function (m, index) {
              return _c("GmapMarker", {
                key: index,
                attrs: {
                  position: m.position,
                  clickable: true,
                  draggable: true,
                },
                on: {
                  click: function ($event) {
                    _vm.center = m.position
                  },
                },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <GmapMap\n    :center="center"\n    :zoom="7"\n    map-type-id="terrain"\n    style="width: 100%; height: 400px"\n    @click="mapClicked"\n  >\n    <GmapMarker\n      :key="index"\n      v-for="(m, index) in markers"\n      :position="m.position"\n      :clickable="true"\n      :draggable="true"\n      @click="center=m.position"\n    />\n  </GmapMap>\n</template>\n\n<script>\nexport default {\n  data() {\n    return {\n      center: { lat: 10.0, lng: 10.0 },\n      markers: [\n        { position: { lat: 10.0, lng: 10.0 } },\n        { position: { lat: 11.0, lng: 11.0 } },\n      ]\n    }\n  },\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }